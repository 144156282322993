<template>
    <div >
        <div style="background: #FAFAFA;padding-bottom:2%">
            <div class="appmain">
                <el-row>
                    <el-col :span="12" :xs="24" >
                        <div style="position: relative;">
                            <el-carousel indicator-position="none" height="350px" :autoplay="false" ref="carouser" @change="onchange">
                                <el-carousel-item v-for="(item,index) in imageList" :key="index" ref="ss">
                                    <div class="image-container" @mousemove.stop="moveLens" @mouseover.stop="showZoom(index)" @mouseleave.stop="hideZoom" ref="small_div" >

                                        <video v-if="isVideo(imageList[index].product_img)"
                                            :src="imageList[index].product_img" 
                                            controls
                                            class="video-element">
                                        </video>

                                        <el-image v-else :src="imageList[index].product_img" alt="" style="height: 100%;" ref="small_img"/>
                                        <div class="move_div" v-if="moveDiv" ref="moveDiv"></div>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                            <div class="thumbnail-nav" v-if="imageList.length != 1">
                                <button class="nav-button prev" @click="scroll(-1)" :disabled="shang">&#10094;</button>
                                <div class="thumbnails-wrapper">
                                    <div class="thumbnails-container" >
                                    <div v-for="(item, index) in images" :key="index" class="thumbnail" @click="goToSlide(index+dianji)">
                                        <!-- <el-image v-if="isVideo(item.product_img)" :src="images[1].product_img"></el-image> -->
                                        <div class="aaaa" v-if="isVideo(item.product_img)">
                                            <el-image :src="images[1].product_img" class="bbbb"></el-image>
                                            <i class="cccc el-icon-video-play"></i>
                                        </div>

                                        <el-image v-else :src="item.product_img"></el-image>
                                    </div>
                                    </div>
                                </div>
                                <button class="nav-button next" @click="scroll(1)" :disabled="xia">&#10095;</button>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" :xs="24">
                        <div class="big_div" v-if="bigDiv" ref="big_div">
                            <img :src="imageList[tabindex].product_img" alt="" class="big_img" ref="big_img">
                        </div>
                        <div class="productinfo">
                            <div class="productname">
                                {{info.product_name}}
                            </div>
                            <div class="detail">
                                {{info.product_feature}}
                            </div>
                            <div>
                                <div style="margin-top:10px;font-size: 16px;" v-if="info.dc_supply != ''">{{$t('product.WorkingPowerRange')}} : {{info.dc_supply}}</div>
                                <div style="margin-top:10px;font-size: 16px;" v-if="info.case_dim != ''">{{$t('product.OverallDimension')}} : {{info.case_dim}}</div>
                                <div style="margin-top:10px;font-size: 16px;" v-if="info.panel_cutout != ''">{{$t('product.InstallationDimension')}} : {{info.panel_cutout}}</div>
                                <div style="margin-top:10px;font-size: 16px;" v-if="info.operating_temp != ''">{{$t('product.WorkingTemperature')}} : {{info.operating_temp}}</div>
                                <div style="margin-top:10px;font-size: 16px;" v-if="info.weight != ''">{{$t('product.weight')}} : {{info.weight}}</div>
                                <div style="margin-top:10px;font-size: 16px;" v-if="info.ziti != ''">{{$t('product.ProductReferenceFont')}} : {{info.ziti}}</div>
                            </div>
                            <div class="fankui" @click="wenti" v-if="$i18n.locale != 'en'">
                                {{$t('product.problemFeedback')}}
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="tabcontent">
            <div class="menuslist" >
                <el-tabs :stretch="true" v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane :label="$t('product.productOverview')" name="first">
                        <div style="width:80%;margin:0 auto">
                            <div v-html="info.product_desc" ></div>
                            <div class="pinglun" v-if="this.$i18n.locale == 'zh'">
                                <div class="pltitle">{{$t('pinglun.fabiaopinglun')}}</div>
                                <div class="plinput">
                                    <el-input type="textarea" :rows="5" :placeholder="$t('pinglun.shuodianshenme')" v-model="pingluncontent"></el-input>
                                    <div style="display: flex;justify-content: flex-end;"><div class="plbtn" @click="pinglunbtn">{{$t('pinglun.pinglun')}}</div></div>
                                </div>
                                <div class="pllist">
                                    <div class="allpltitle">{{$t('pinglun.quanbupinglun')}}<span class="plnum">{{pinglunlist.length}}</span></div>
                                    <div v-for="(item,index) in pinglunlist" :key="index">
                                        <div class="plitem">
                                            <div class="plimage">
                                                <el-image style="width: 50px;height: 50px;border-radius: 50%;" :src="item.user_image"></el-image>
                                            </div>
                                            <div class="plright">
                                                <div class="plname">{{item.user_name}}</div>
                                                <div class="content">{{item.content}}</div>
                                                <div class="ploption">
                                                    <div class="pldate">{{item.addtime}}</div>
                                                    <div class="plicon" style="display:flex">
                                                        <div @click="huifu(item)" style="margin-top:2px;">
                                                            <el-image :src="require('@/assets/huifu.png')" ></el-image>
                                                        </div>
                                                        <div style="margin-left:25px;margin-top:2px;" @click="dianzan1(item)">
                                                            <el-image :src="item.like ? require('@/assets/dianzan_active.png') : require('@/assets/dianzan.png')" ></el-image>
                                                        </div><span style="margin-left:5px" :style="{color:item.like ? '#FAD20C' : ''}">{{item.like_num}}</span>
                                                    </div>
                                                </div>
                                                <div class="huifu" v-if="item.textarea">
                                                    <el-input type="textarea" :rows="2" :placeholder="$t('pinglun.huifu')+hfname" v-model="huifucontent"></el-input>
                                                    <div style="display: flex;justify-content: flex-end;"><div class="hfbtn" @click="huifubtn(item.id,2,item.id)">{{$t('pinglun.huifu')}}</div></div>
                                                </div>
                                                <div v-for="(items,indexs) in item.get_reply" :key="indexs" v-show="item.allopen || indexs<3">
                                                    <div class="plitem" >
                                                        <div class="plimage">
                                                            <el-image style="width: 50px;height: 50px;border-radius: 50%;" :src="items.user_image"></el-image>
                                                        </div>
                                                        <div class="plright">
                                                            <div class="plname">{{items.user_name}} <span v-if="items.form_name">->{{items.form_name}}</span></div>
                                                            <div class="content">{{items.content}}</div>
                                                            <div class="ploption">
                                                                <div class="pldate">{{item.addtime}}</div>
                                                                <div class="plicon" style="display:flex">
                                                                    <div @click="huifu(items)" style="margin-top:2px;">
                                                                        <el-image :src="require('@/assets/huifu.png')" ></el-image>
                                                                    </div>
                                                                    <div style="margin-left:25px;margin-top:2px;" @click="dianzan2(items)">
                                                                        <el-image :src="items.like ? require('@/assets/dianzan_active.png') : require('@/assets/dianzan.png')" ></el-image>
                                                                    </div><span style="margin-left:5px" :style="{color:items.like ? '#FAD20C' : ''}">{{items.like_num}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="huifu" v-if="items.textarea">
                                                        <el-input type="textarea" :rows="2" :placeholder="$t('pinglun.huifu')+hfname" v-model="huifucontent"></el-input>
                                                        <div style="display: flex;justify-content: flex-end;"><div class="hfbtn" @click="huifubtn(item.id,1,items.id)">{{$t('pinglun.huifu')}}</div></div>
                                                    </div>
                                                </div>
                                                <div style="font-size: 14px;color: #4E4D47;margin-top:15px" v-if="!item.allopen && item.get_reply.length>3">共{{item.get_reply.length}}条评论，<span style="color: #0052D9;cursor:pointer" @click="showall(item)">点击查看</span></div>
                                            </div>
                                        </div>
                                        <el-divider></el-divider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('product.technicalParameters')" name="second">
                        <div style="margin-top:2%">
                            <div class="jishu" v-for="(item,index) in parameter" :key="index" :style=" {'background' : (index%2 ==0) ? '#F9F9F9' : ''}">
                                <div class="title">{{item.item_title}}</div>
                                <div class="num" style="white-space: pre-line;">{{item.para_value}}</div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('product.shipin')" name="third" v-if="shipin.length>0">
                        <div style="width:80%;margin:0 auto;margin-top:2%">
                            <el-row :gutter="40">
                                <el-col :span="6" :xs="24" v-for="(item,index) in shipin" :key="index">
                                    <div style="cursor:pointer" @click="shipinbofang(item.vid)">
                                        <div style="width:225px;margin:0 auto">
                                            <el-image style="width:100%" :src="item.img"></el-image>
                                        </div>
                                        <div style="text-align: center;">{{item.title}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('product.ziliao')" name="fourth">
                        <el-table
                            :data="download"
                            :row-style="{fontSize:'14px',color:'#333',fontFamily:'SourceHanSansCN-Regular',fontWeight: '400'}"
                            :header-cell-style="{'color':'#333','border-top':'solid 1px #0052D9','background': '#EEEEEE','fontWeight': 'bold'}"
                            header-align='center'
                            style="width: 80%;margin:0 auto;margin-top:2%">
                            <el-table-column align='center' :label="$t('dataType')" prop="downname"></el-table-column>
                            <el-table-column
                                align='center'
                                prop="version"
                                :label="$t('product.versionNumber')"
                                >
                            </el-table-column>
                            <el-table-column
                                align='center'
                                prop="down_value"
                                :label="$t('product.downloadLink')">
                                <template slot-scope="scope">
                                    <span @click="xiazai(scope.row.down_value,scope.row.down_type)" style="cursor:pointer;">{{$t('download')}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('scheme.relatedProducts')" name="chanpin" v-if="products.length>0">
                        <div class="jiejue">
                            <el-row :gutter="40">
                                <el-col :span="6" :xs="24" v-for="(item,index) in products" :key="index">
                                    <div style="cursor:pointer;background: #FAFAFA;padding-bottom: 30px;" @click="nav('product/'+item.products_id)">
                                        <div style="width:225px;margin:0 auto">
                                            <el-image style="width:100%" :src="item.image"></el-image>
                                        </div>
                                        <div class="jjtitle">{{item.product_name}}</div>
                                        <div style="text-align: center;margin-top:2%;color:rgb(51,51,51,0.6);font-size: 12px;">{{item.product_feature}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('product.relatedCases')" name="anli" v-if="project.length>0">
                        <div class="anli">
                            <el-row :gutter="40">
                                <el-col :span="8" :xs="24" v-for="(item,index) in project" :key="index">
                                    <div class="alitem" @click="nav('projectinfo/'+item.project_id)">
                                        <el-image fit="contain" style="width:100%;" class="alimage" :src="item.project_img"></el-image>
                                        <div class="alinfo" >
                                            <div class="altitle">{{item.project_title}}</div>
                                            <div class="aldate">{{item.ymd}}</div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('product.relevantSolutions')" name="fangan" v-if="scheme.length>0">
                        <div class="jiejue" >
                            <el-row :gutter="40">
                                <el-col :span="8" :xs="24" v-for="(item,index) in scheme" :key="index">
                                    <div class="jjitem" @click="nav('scheme/'+item.scheme_id)">
                                        <el-image class="jjimage" :src="item.scheme_image"></el-image>
                                        <div class="jjtitle">{{item.scheme_title}}</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import { productsInfo,commentList,commentReplyAdd,commentAdd,commentLike,commentReplyLike} from "@/api/api"; 
export default {
    data(){
        return{
            title:'',
            tabindex:0,
            activeName: 'first',
            productid:'',
            info:{get_image_list:[{}]},
            parameter:[],
            shipin:[],
            download:[],
            project:[],
            scheme:[],
            imageList:[],
            page:1,
            products:[],
            pingluncontent:'',
            huifucontent:'',
            pinglunlist:[],
            pingluntotal:0,
            hfname:'',
            userinfo:{},
            moveDiv: false,
            bigDiv: false,
            dianji: 0,
            shang: true,
            xia:false,
            images: []
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.indexkeywords')
            },
            {
                name: 'description',
                content: this.$t('seo.indexdescription')
            }
        ]
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    created(){
        this.userinfo = sessionStorage.getItem('userinfo') ? sessionStorage.getItem('userinfo') : ''
        if(this.userinfo != ''){
            this.userinfo = JSON.parse(this.userinfo)
        }
        let productid  = this.$route.params.id
        this.productid = productid
        this.getList()
        this.getpinglun()
    },
    methods:{
        isVideo(src) {
            return src.endsWith('.mp4') || src.endsWith('.avi') || src.endsWith('.mov');
        },
        visibleImages() {
            this.images = this.imageList.slice(this.dianji, this.dianji + 5);
        },
        scroll(direction) {
            if (direction === 1) {
                if (this.imageList.length - 5 - this.dianji>0) {
                    this.dianji += 1
                    this.visibleImages()
                    this.shang = false
                } else {
                    this.xia = true
                }
            } else if (direction === -1) {
                if (this.dianji > 0) {
                    this.dianji -= 1
                    this.visibleImages()
                    this.xia = false
                } else {
                    this.shang = true
                }
            }
        },
        onchange(value) {
            this.tabindex = value
        },
        getOffsetLeft(element) {
            let offsetLeft = 0;
            while (element) {
                offsetLeft += element.offsetLeft;
                element = element.offsetParent;
            }
            return offsetLeft;
        },
        moveLens(e) {
            const smallDiv = this.$refs.small_div[this.tabindex];
            const moveDiv = this.$refs.moveDiv[this.tabindex];
            const bigImg = this.$refs.big_img;
            if (bigImg) {
                const leftDistance = this.getOffsetLeft(smallDiv);
                let x = e.pageX - smallDiv.offsetLeft - moveDiv.offsetWidth / 2  -leftDistance*0.75;
                let y = e.pageY - smallDiv.offsetTop - moveDiv.offsetHeight / 2 - 100;
                // 处理左右边界
                if (x > smallDiv.offsetWidth - moveDiv.offsetWidth) {
                    x = smallDiv.offsetWidth - moveDiv.offsetWidth;
                } else if (x < 0) {
                    x = 0;
                }
                
                // // 处理上下边界
                if (y > smallDiv.offsetHeight - moveDiv.offsetHeight) {
                    y = smallDiv.offsetHeight - moveDiv.offsetHeight - 200;
                } else if (y < 0) {
                    y = 0;
                }
                
                // 放大镜移动
                moveDiv.style.left = x + "px";
                moveDiv.style.top = y + "px";

                // 被放大的图片移动，放大镜右移时图片左移
                bigImg.style.left = -x * bigImg.offsetWidth / smallDiv.offsetWidth + "px";
                bigImg.style.top = -y * bigImg.offsetHeight / smallDiv.offsetHeight + "px";
            }
        },
        showZoom(index) {
            if (!this.isVideo(this.imageList[index].product_img)) {
                this.bigDiv = true
            }
            this.moveDiv = true

        },
        hideZoom() {
            this.moveDiv = false
            this.bigDiv = false
        },
        goToSlide(index) {
            this.tabindex = index
            this.$refs.carouser.setActiveItem(index)
        },
        async getList(){
            const {data:res} = await productsInfo(this.productid)
            res.products.forEach((item)=>{
                item.image = process.env.VUE_APP_BASE_API+item.get_image_list[0].product_img
                delete item.get_image_list
            })
            
            res.info.get_image_list.forEach((item)=>{
                item.product_img = process.env.VUE_APP_BASE_API+item.product_img
            })
            res.project.forEach((item)=>{
                item.project_img = process.env.VUE_APP_BASE_API+item.project_img
            })
            res.scheme.forEach((item)=>{
                item.scheme_image = process.env.VUE_APP_BASE_API+item.scheme_image
            })
            res.shipin.forEach((item)=>{
                item.img = process.env.VUE_APP_BASE_API+item.img
            })
            this.products = res.products
            this.imageList = res.info.get_image_list
            console.log(this.imageList);
            if (this.imageList.length<5) {
                this.xia = true
            }
            this.visibleImages()
            this.info = res.info
            this.parameter = res.parameter
            this.shipin = res.shipin
            this.download = res.download
            this.project = res.project
            this.scheme = res.scheme
            this.title = res.info.product_name+'-'+this.$t('seo.indextitle')
        },
        async getpinglun(){
            let user_id = ''
            if(this.userinfo != ''){
                user_id = this.userinfo.userid
            }
            const {data:res} = await commentList({topic_id:this.productid,topic_type:1,user_id:user_id})
            let data = this.initdata(res.list)
            this.pinglunlist = data
            this.pingluntotal = res.total
        },
        showall(item){
            this.pinglunlist = this.initdata(this.pinglunlist)
            item.allopen = true
        },
        initdata(data){
            data.forEach((item,index)=>{
                item.textarea = false
                item.allopen = false
                if(item.get_reply.length>0){
                    item.get_reply.forEach((items,indexs)=>{
                        items.textarea = false
                    })
                }
            })
            return data
        },
        handleClick(tab, event) {
            
        },
        wenti(){
            this.$router.push('/feedback','_blank')
        },
        xiazai(url,type){
            let userinfo = sessionStorage.getItem('userinfo') ? sessionStorage.getItem('userinfo') : ''
            if(userinfo == ''){
                if(type == 4 || type == 10 || type == 11){
                    this.$confirm(this.$t('loginerror'), this.$t('notLogIn'), {
                    confirmButtonText: this.$t('logIn'),
                    cancelButtonText: this.$t('close'),
                    type: 'warning'
                    }).then(() => {
                        this.$router.push('/login')
                    }).catch(() => {
                    });
                }else{
                    window.open(process.env.VUE_APP_BASE_API+url)
                }
            }else{
                window.open(process.env.VUE_APP_BASE_API+url)
            }
        },
        nav(url){
            this.$router.push('/'+url,'_blank')
        },
        shipinbofang(vid){
            window.open('https://v.qq.com/x/page/'+vid+'.html')
        },
        jiaoyan(){
            this.$confirm(this.$t('loginerror'), this.$t('notLogIn'), {
            confirmButtonText: this.$t('logIn'),
            cancelButtonText: this.$t('close'),
            customClass: 'myConfirm',
            type: 'warning'
            }).then(() => {
                this.$router.push('/login')
            }).catch(() => {
            });
        },
        huifu(item){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }else{
                this.huifucontent  = ''
                this.hfname = item.user_name
                this.pinglunlist = this.initdata(this.pinglunlist)
                item.textarea = true
            }
        },

        async pinglunbtn(){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }else{
                let data = {
                    topic_id:this.productid,
                    topic_type:1,
                    content:this.pingluncontent,
                    user_name:this.userinfo.name,
                    user_id:this.userinfo.userid,
                    user_image:this.userinfo.photo
                }
                const {data:res} = await commentAdd(data)
                this.pingluncontent = ''
                this.$message.success('评论成功');
                this.getpinglun()
            }
        },
        async huifubtn(comment_id,reply_type,reply_id){
            let data = {
                comment_id:comment_id,
                reply_type:reply_type,
                reply_id:reply_id,
                content:this.huifucontent,
                user_name:this.userinfo.name,
                user_id:this.userinfo.userid,
                user_image:this.userinfo.photo
            }
            const {data:res} = await commentReplyAdd(data)
            this.$message.success('回复成功');
            this.getpinglun()
        },
        async dianzan1(item){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }
            let data ={
                id:item.id,
                user_id:this.userinfo.userid
            }
            await commentLike(data)
            item.like = true
            item.like_num = item.like_num+1
        },
        async dianzan2(item){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }
            let data ={
                id:item.id,
                user_id:this.userinfo.userid
            }
            await commentReplyLike(data)
            item.like = true
            item.like_num = item.like_num+1
        },
        durlnav(){
            window.open("/image3d/"+this.info.product_id, '_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 65%;
        margin: 0 auto;
        padding-top:2%;
    }
    // 手机端样式
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
        .jishu{
            display: block !important;
            height: 80px !important;
            .title{
                width: 100% !important;
            }
            .num{
                padding-left: 3% ;
            }
        }
    }
    .image{
        width: 302px;
        margin: 0 auto;
    }
    .tabcontent{
        width: 100%;
    }
    .imagelist{
        display: flex;
        margin-top: 2%;
        .lefts{
            width: 30px;
            height: 130px;
            cursor: pointer;
            position: relative;
        }
        .jian{
            position: absolute;
            top: 45%;
            left: 22%;
        }
        .rights{
            width: 30px;
            height: 130px;
            margin-left: 20px;
            cursor: pointer;
            position: relative;
        }
        .imageitem{
            width: 122px;
            height: 122px;
            margin-left: 12px;
            opacity:0.5;
        }
        .active{
            opacity:1;
            border: 1px solid #0052D9;
        }
    }
    .productinfo{
        width: 80%;
        margin: 0 auto;
        margin-top: 5%;
        .productname{
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-size: 40px;
            font-weight: bold;
            color: #333333;
        }
        .detail{
            margin-top: 12px;
            font-size: 18px;
            font-weight: 500;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        }
        .fankui{
            margin-top: 30px;
            width: 152px;
            border: 1px solid #0052D9;
            height: 48px;
            text-align: center;
            line-height: 48px;
            color: #0052D9;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            cursor:pointer;
            font-size: 16px;

        }
    }
    .menuslist{
        width: 100%;
        margin: 0 auto;
        margin-top: 1%;
        .pinglun{
            .pltitle{
                font-size: 22px;
                font-weight: bold;
                color: #333333;
            }
            .plinput{
                margin-top: 20px;
                .plbtn{
                    width: 132px;
                    height: 48px;
                    background: #0052D9;
                    text-align: center;
                    line-height: 48px;
                    color: #fff;
                    margin-top: 10px;
                    cursor: pointer;
                }
            }
            .pllist{
                .allpltitle{
                    font-weight: bold;
                    color: #4E4D47;
                    .plnum{
                        color: rgba(78, 77, 71, 0.5);
                        margin-left: 10px;
                    }
                }
                .plitem{
                    display: flex;
                    margin-top: 15px;
                    .plimage{
                        margin-top: 5px;
                    }
                    .plright{
                        width: 100%;
                        margin-left: 10px;
                        .plname{
                            font-size: 14px;
                            font-weight: bold;
                            color: #4E4D47;
                        }
                        .content{
                            margin-top: 10px;
                            font-size: 14px;
                            color: #4E4D47;
                        }
                        .ploption{
                            display: flex;
                            justify-content: space-between;
                            .pldate{
                                margin-top: 10px;
                                font-size: 13px;
                                color: rgba(78, 77, 71, 0.4);
                            }
                            .plicon{
                                cursor: pointer;
                                font-size: 16px;
                                color: rgba(78, 77, 71, 0.4);
                            }
                        }
                        .huifu{
                            margin-top: 10px;
                            .hfbtn{
                                width: 80px;
                                height: 30px;
                                border: 1px solid #0052D9;
                                text-align: center;
                                line-height: 30px;
                                margin-top: 10px;
                                color: #0052D9;
                                font-size: 14px;
                                cursor: pointer;
                            }
                        }
                    }
                    
                }
            }
        }
        .jishu{
            width: 80%;
            margin: 0 auto;
            display: flex;
            min-height: 40px;
            line-height: 40px;
            .title{
                width: 30%;
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                padding-left: 3%;
            }
            .num{
                font-weight: 500;
            }
        }
        .anli{
            width: 80%;
            margin: 0 auto;
            margin-top: 2%;
            .alitem{
                margin-bottom: 20px;
                margin-left: 2%;
                padding-bottom: 20px;
                background: #FAFAFA;
                cursor: pointer;
            }
            .alinfo{
                width: 90%;
                margin: 0 auto;
                margin-top: 3%;
                .altitle{
                    width:100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 3%;
                    font-size: 16px;
                    font-weight: 550;                    
                }
                .aldate{
                    color: #6e6d6d;
                    font-size: 14px;
                    font-family: OPPOSans-M;
                }
            }
            
        }
        .jiejue{
            width: 80%;
            margin: 0 auto;
            margin-top: 2%;
            .jjitem{
                cursor: pointer;
                margin-left: 2%;
                padding-bottom: 20px;
                background: #FAFAFA;
            }
            .jjimage{
                width: 100%;
            }
            .jjtitle{
                color: #333333;
                font-weight: 550;
                text-align: center;
                font-family: OPPOSans-H;
                height: 25px;
            }
        }
    }
</style>
<style scoped>
.aaaa {
  position: relative;
}

.bbbb {
  width: 100%;
  height: auto;
  filter: blur(1px); 
}

.cccc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: rgb(240, 236, 236);
  cursor: pointer;
}

    .thumbnail-nav {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .thumbnails-wrapper {
        overflow: hidden;
        position: relative;
    }

    .thumbnails-container {
        display: flex;
        transition: transform 0.3s ease;
    }

    .thumbnail {
        flex: 0 0 auto;
        width: 80px; /* 设置缩略图宽度 */
        height: 80px; /* 设置缩略图高度 */
        border: 1px solid #ccc;
        margin: 0 5px;
    }

    .nav-button {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
    }

    .prev {
        margin-right: 10px;
    }

    .next {
        margin-left: 10px;
    }

    .image-container {
        display: flex;
        position: relative;
        justify-content: center; /* 水平居中 */
        width: 400px;
        height: 400px;
        margin:0 auto;
    }

    .video-element {
        width: 90%;
        height: 90%;
    }

    .big_div {
        position: fixed;
        overflow: hidden;
        border: 1px solid #ccc;
        background-color: #fff;
        z-index: 1000;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transition: opacity 0.3s ease;
        width:540px;
        height: 540px;
    }

    .move_div {
        height: 200px;
        width: 200px;
        background-color: yellow;
        position: absolute;
        opacity: 0.3;
    }

    .big_img {
        position: absolute;
        width: 150%;
    }

    /deep/ .el-tabs__nav-wrap::after {
        position: static !important;
    }
    /deep/ .el-tabs__header{
        width: 80%;
        margin: 0 auto;
    }
    /deep/ .el-tabs__item.is-active{
        color: #0052d9;
    }
    /deep/ .el-tabs__item:hover{
        color: #0052d9;
    }
    /deep/ .el-tabs__active-bar{
        background-color:#0052d9;
    }
    .pinglun >>> .el-textarea__inner{
        background: #F9F9F9;
    }
</style>